import React, { useState, useCallback, useMemo } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';

import logo from '../../assets/logo.png';

import HomeMenu from '../HomeMenu';

import { Container, Content, Header, LogoImg, MenuContent } from './styles';

const Aside: React.FC = () => {
  const [click, setClick] = useState(false);

  const handleClick = useCallback(() => {
    setClick(!click);
  }, [click]);

  return (
    <Container click={click}>
      <button onClick={handleClick}>
        {click ? <FiChevronRight /> : <FiChevronLeft />}
      </button>

      <Content click={click}>
        <MenuContent>
          <HomeMenu />
        </MenuContent>
      </Content>
    </Container>
  );
};

export default Aside;
