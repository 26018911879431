import React, { useMemo } from 'react';

import { Container, Content } from './styles';

const MainHeader: React.FC = () => {
  return (
    <Container>
      <strong>Stacks</strong>
      <Content>
        <img
          alt="robighetti-Js"
          height="30"
          width="40"
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-plain.svg"
        />

        <img
          alt="robighetti-Ts"
          height="30"
          width="40"
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-plain.svg"
        />

        <img
          alt="robighetti-NODEJS"
          height="30"
          width="40"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg"
        />

        <img
          alt="robighetti-React"
          height="30"
          width="40"
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg"
        />

        <img
          alt="robighetti-HTML"
          height="30"
          width="40"
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg"
        />

        <img
          alt="robighetti-CSS"
          height="30"
          width="40"
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg"
        />

        <img
          alt="robighetti-GIT"
          height="30"
          width="40"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"
        />

        <img
          alt="robighetti-DOCKER"
          height="30"
          width="40"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-plain-wordmark.svg"
        />

        <img
          alt="robighetti-POSTGRES"
          height="30"
          width="40"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original-wordmark.svg"
        />

        <img
          alt="robighetti-MONGO"
          height="30"
          width="40"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg"
        />

        <img
          alt="robighetti-AZURE"
          height="30"
          width="40"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original.svg"
        />

        <img
          alt="robighetti-DIGITALOCEAN"
          height="30"
          width="40"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/digitalocean/digitalocean-original.svg"
        />

        <img
          alt="robighetti-LINUX"
          height="30"
          width="40"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg"
        />

        <img
          alt="robighetti-LINUX"
          height="30"
          width="40"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jira/jira-original.svg"
        />
      </Content>
    </Container>
  );
};

export default MainHeader;
