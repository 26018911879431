import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding-right: 16px;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: -16px;

  > img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 3px solid ${({theme}) => theme.colors.secondary};
  }
`;

export const Content = styled.section`
  height: 100%;
  width: 100%;

  margin-top: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > hr {
    margin: 0 16px;
  }

  > span {
    text-align: center;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({theme}) => theme.fonts.regular};
  text-align: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    strong {
      font-size: 34px;
      margin-bottom: 8px;

      display: flex;
      align-items: center;

      button {
        background: transparent;
        border: none;

        svg {
          margin-left: 16px;
          font-size: 30px;
          color: ${({theme}) => theme.colors.secondary};
          transition: all 0.3s;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  > span {
    font-size: 16px;
  }
`;

export const InfoLabel = styled.section`
  display: flex;
  flex-direction: column;
  font-family: ${({theme}) => theme.fonts.regular};

  label {
    color: ${props => props.theme.colors.title};
    font-size: 16px;

    strong {
      color: ${props => props.theme.colors.success};
      margin-left: 16px;
      font-size: 14px;
    }
  }
`;

export const ActionsButton = styled.section``;

export const SocialMedia = styled.div``
