import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Layout from '../components/Layout';

import Home from '../pages/Home';
import Projects from '../pages/Projects';
import VemSerDev from '../pages/VemSerDev';
import Certifications from '../pages/Certifications';

const Routes: React.FC = () => (
  <Switch>
    <Layout>
      <Route path="/" exact component={Home} />
      <Route path="/certifications" component={Certifications} isPrivate />
      <Route path="/projects" component={Projects} isPrivate />
      <Route path="/vem-ser-dev" component={VemSerDev} isPrivate />
    </Layout>
  </Switch>
);

export default Routes;
