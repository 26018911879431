import styled from 'styled-components';

export const Container = styled.div`
  grid-area: MH;
  background-color: ${props => props.theme.colors.background};
  font-family: ${({theme}) => theme.fonts.regular};

  padding: 0 16px;
  border-bottom: 1px solid ${props => props.theme.colors.primary};

  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    margin-left: 16px;
  }
`;

export const Content = styled.div``;
