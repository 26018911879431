import React from 'react';

import { Container } from './styles';
import './styles.css';

const SocialMedia: React.FC = () => {
  return (
    <Container>
      <ul>
        <li>
          <a href="https://github.com/robighetti" target="_blank">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span className="fa fa-github" aria-hidden="true"></span>
          </a>
        </li>

        <li>
          <a
            href="https://www.linkedin.com/in/rodrigo-bighetti/"
            target="_blank"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span className="fa fa-linkedin" aria-hidden="true"></span>
          </a>
        </li>
      </ul>
    </Container>
  );
};

export default SocialMedia;
