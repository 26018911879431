import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

import githubApi from '../../api/githubApi';

import SocialMedia from '../../components/SocialMedia';
import Button from '../Button';

import {
  Container,
  Header,
  Content,
  UserInfo,
  InfoLabel,
  ActionsButton,
} from './styles';

interface GithubProps {
  login: string;
  bio: string;
  company: string;
  email: string;
  name: string;
  url: string;
  avatar_url: string;
  location: string;
  blog: string;
}

const HomeMenu: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const [githubData, setGithubData] = useState<GithubProps>({} as GithubProps);

  const handleGithubGet = useCallback(async () => {
    const { data } = await githubApi.get('users/robighetti');

    console.log(data);
    setGithubData(data);
  }, []);

  useEffect(() => {
    handleGithubGet();
  }, []);

  return (
    <Container>
      <Header>
        <img src={githubData.avatar_url} alt="Imagem do Avatar" />
      </Header>

      <Content>
        <UserInfo>
          <div>
            <strong>{githubData.name}</strong>
          </div>

          <span>{githubData.bio}</span>
          <span>{githubData.email || 'robighetti@gmail.com'}</span>
          <strong id="numPtam">{githubData.location}</strong>
        </UserInfo>

        <hr />

        <span>
          Success is the sum of small efforts repeated day by day. 💪🚀
        </span>

        <ActionsButton>
          <Button
            style={{
              background: theme.colors.primary,
            }}
            onClick={() => history.push('/')}
          >
            Home
          </Button>

          <Button
            style={{
              background: theme.colors.primary,
            }}
            onClick={() => history.push('/certifications')}
          >
            Certifications
          </Button>
          <Button
            style={{
              background: theme.colors.primary,
            }}
            onClick={() => history.push('/projects')}
          >
            Personal Projects
          </Button>
          <Button
            style={{
              background: theme.colors.secondary,
            }}
            onClick={() => history.push('/vem-ser-dev')}
          >
            Vem Ser Dev Project
          </Button>
        </ActionsButton>

        <SocialMedia />
      </Content>
    </Container>
  );
};

export default HomeMenu;
